define(['jquery', 'handlebars', 'storeFinderTpl', 'siteObj', 'module'], function($, handlebars, storeFinderTpl, siteObj) {
  
  var data;
  var storefinder = {

    init: function(config) {
      storefinder.events(config);
    },
    events: function(config) {
      if (config !== false && $(config.selectors.trigger).length) {
        $(config.selectors.trigger).click(function() {

          var dataLocationArray = [$('#city').val(),
            $('#postcode').val(),
            $('#country').val()];

          for (var i = 0; i < dataLocationArray.length; i++) {
            if (dataLocationArray[i] === '') {
              dataLocationArray.splice(i, 1);
            }
          }
          var dataLocation = dataLocationArray.join('+');
          var dataRadious = $('#radius').val();
          var dataExtra = '';
          if ($('#extra:checkbox:checked').length > 0) {
            dataExtra = 'speed';
          }
          var dataUnits = 'metric';
          var dataFormat = 'jsonp';
          data = '?location=' + dataLocation +
            '&radius=' + dataRadious +
            '&units=' + dataUnits +
            '&format=' + dataFormat;
          if (dataExtra) {
            data += '&extra=speed';
          } else {
            data += '&extra=';
          }
          storefinder.populate.init(config, data);
          return false;
        });
      }
    },
    render: function(config, data) {
      var content = storeFinderTpl(data);
      storefinder.populate.page(config, content);
    },
    populate: {
      init: function(config, data) {

        $(config.selectors.content).append(config.initialContent);

        storefinder.request(config, data);
      },
      page: function(config, data) {

        //remove current content
        if ($(config.selectors.content).length) {
          $(config.selectors.content).empty();
        }

        $(config.selectors.content).append(data);
      },
      error: function(config) {
        //todo add error handling
      }
    },
    request: function(config) {
      var ea_api = '/storeFinder.awesome';

      data = $.ajax({
        type: 'GET',
        url: ea_api + encodeURI(data) + '&callback=apicallback',
        crossDomain: true,
        async: false,
        cache: false,
        dataType: 'jsonp',
        success: function apicallback(response) {

          var storeStatus = response.status,
            storeCount = response.location_count,
            storeLocations = response.locations;

          if (storeStatus === 'ok' && storeCount >= 1 && storeLocations !== null) {
            storefinder.render(config, response);
          } else {
            // no valid results found
            $(config.selectors.content).empty();

            $(config.selectors.content).append(config.errorContent);
          }

        }
      });
    }
  };

  storefinder.init({
    'selectors': {
      'trigger': '.store-btn',
      'content': '.store-lookup-content'
    },
    'initialContent': '<div class=\'loading\'><img src=' + siteObj.siteNonSecureURL + siteObj.loadingGifPath + ' alt=\'Loading\' /></div>',
    'errorContent': '<div class=\'error\'><p> Sorry, but we couldn\'t find any results for your search </p></div>'
  }
  );

  return storefinder;
});
